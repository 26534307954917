import * as React from "react";
import { IC_DISCOUNT_RATE } from "../utils/constants";
import { makeStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button/Button";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Typography from "@material-ui/core/Typography/Typography";

const useStyles = makeStyles(theme => ({
  bodyText: {
    marginBottom: theme.spacing.unit * 2
  }
}));

const InnerCircleHelpDialog = props => {
  const classes = useStyles();

  const H = ({ children }) => (
    <Typography component="p" variant="h6">
      {children}
    </Typography>
  );

  const P = ({ children }) => (
    <Typography component="p" variant="body1" className={classes.bodyText}>
      {children}
    </Typography>
  );

  return (
    <>
      <DialogTitle id="alert-dialog-title">
        How Can I Get A Discount?
      </DialogTitle>
      <DialogContent>
        <div>
          <P>
            Sign up for Neal Morse's{" "}
            <a
              href="https://www.nealmorse.com/#circle"
              target="_blank"
              rel="noopener noreferrer"
            >
              Inner Circle
            </a>{" "}
            to receive a discount on your Waterfall subscription! All you need
            to do is sign up with the <strong>same email address</strong> as
            your Waterfall account, and refresh this page to see the discounted
            rate become available.
          </P>
          <H>I Am Already An Inner Circle Member</H>
          <P>
            If you are already an Inner Circle member and you aren't seeing the
            discounted rate, take the following steps:
          </P>
          <ul>
            <li>
              <Typography>
                Make sure your Waterfall account's email address{" "}
                {props.email ? <strong>({props.email})</strong> : ""} is the{" "}
                <em>exact</em> same address as your Inner Circle account's email
                address.
              </Typography>
            </li>
            <li>
              <Typography>
                If you are a long-time Inner Circle member and haven't logged
                into Neal Morse's new{" "}
                <a
                  href="https://www.nealmorse.com/my-account/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  website
                </a>{" "}
                yet (launched late 2019), log in there with your old account.
                You will probably need to use the{" "}
                <a
                  href="https://www.nealmorse.com/wp-login.php?action=lostpassword"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Lost Your Password
                </a>{" "}
                function.
              </Typography>
            </li>
          </ul>
          <P>
            After completing these steps, close this dialog and refresh the page
            in your browser. You should see the discounted rate of{" "}
            <strong>{IC_DISCOUNT_RATE}</strong> be available. If it still is not
            available, send an email to{" "}
            <a href="mailto:radiantstreamingapp@gmail.com">
              radiantstreamingapp@gmail.com
            </a>{" "}
            for further assistance.
          </P>
        </div>
      </DialogContent>
      {props.handleClose && (
        <DialogActions>
          <Button
            onClick={props.handleClose}
            color="primary"
            variant="contained"
          >
            Ok
          </Button>
        </DialogActions>
      )}
    </>
  );
};

export default InnerCircleHelpDialog;
