import * as React from "react";
import { AppContext } from "../App";
import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button/Button";
import ErrorMessage from "../design-system/ErrorMessage";
import FullScreenCard from "../design-system/FullScreenCard";
import SuccessMessage from "../design-system/SuccessMessage";
import Typography from "@material-ui/core/Typography/Typography";

const useStyles = makeStyles(theme => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  },
  bodyText: {
    textAlign: "center",
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2
  }
}));

const CancelSubscription = props => {
  const classes = useStyles();
  const ctx = React.useContext(AppContext);
  const [cancelStatus, setCancelStatus] = React.useState("NONE");

  if (ctx.authApi == null || props.user.userSubscription == null)
    return <Redirect to="/error" />;

  const { authApi } = ctx;
  const { userSubscription } = props.user;
  const handleSubmit = async event => {
    event.preventDefault();
    setCancelStatus("SENDING");

    const resp = await authApi.cancelSubscription(userSubscription);

    if (resp) return setCancelStatus("SUCCESS");

    setCancelStatus("FAILURE");
  };

  const showForm = cancelStatus !== "SUCCESS";
  const requestStatus = (() => {
    switch (cancelStatus) {
      case "NONE":
        return null;

      case "FAILURE":
        return (
          <ErrorMessage>
            Could not cancel your subscription. Please contact support for
            assistance.
          </ErrorMessage>
        );

      case "SUCCESS":
        return (
          <SuccessMessage>Your next renewal has been cancelled.</SuccessMessage>
        );

      case "SENDING":
        return (
          <Typography component="h2" variant="subtitle1">
            Sending...
          </Typography>
        );
    }
  })();

  return (
    <FullScreenCard>
      <Typography component="h1" variant="h5">
        Cancel Subscription
      </Typography>
      <Typography component="p" variant="body1" className={classes.bodyText}>
        Click below to schedule your subscription to be cancelled. Your
        subscription will be cancelled at the end of your next billing cycle.
        You will continue to have access to the service until then.
      </Typography>
      {showForm ? (
        <form className={classes.form} onSubmit={handleSubmit}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Yes, I want to cancel
          </Button>
        </form>
      ) : null}
      {requestStatus}
    </FullScreenCard>
  );
};

export default CancelSubscription;
