/*
 * I tried to update this guy to use hooks, and couldn't get it to work.
 * For some reason, the onChange handler passed to the ReCaptcha component
 * never gets the state values.
 *
 * */

import * as Api from "../Api";
import * as React from "react";
import { AppContext } from "../App";
import { Link } from "react-router-dom";
import { emailIsValid, passwordIsValid } from "../utils/validation";
import AppHeader from "../components/AppHeader";
import Button from "@material-ui/core/Button/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog/Dialog";
import ErrorMessage from "../design-system/ErrorMessage";
import FormControl from "@material-ui/core/FormControl/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import FullScreenCard from "../design-system/FullScreenCard";
import Input from "@material-ui/core/Input/Input";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import PrivacyPolicyDialog from "../components/PrivacyPolicyDialog";
import ReCAPTCHA from "react-google-recaptcha";
import SuccessMessage from "../design-system/SuccessMessage";
import TermsOfServiceDialog from "../components/TermsOfServiceDialog";
import Typography from "@material-ui/core/Typography/Typography";
import env from "../Env";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  }
});

const recaptchaRef = React.createRef();
const recaptchaKey = env.REACT_APP_RECAPTCHA_SITE_KEY;

class Signup extends React.Component {
  state = {
    email: "",
    username: "",
    password: "",
    confirmPassword: "",
    acceptPrivacy: false,
    acceptTos: false,
    signupStatus: { type: "NONE", message: "" },
    captchaPassed: false,
    modal: "NONE"
  };

  updateEmail = ({ target: { value } }) =>
    this.setState({
      email: value,
      signupStatus: { type: "NONE", message: "" }
    });

  updateUsername = ({ target: { value } }) =>
    this.setState({
      username: value,
      signupStatus: { type: "NONE", message: "" }
    });

  updatePassword = ({ target: { value } }) =>
    this.setState({
      password: value,
      signupStatus: { type: "NONE", message: "" }
    });

  updateConfirmPassword = ({ target: { value } }) =>
    this.setState({
      confirmPassword: value,
      signupStatus: { type: "NONE", message: "" }
    });

  updateAcceptPrivacy = () =>
    this.setState({
      acceptPrivacy: !this.state.acceptPrivacy,
      signupStatus: { type: "NONE", message: "" }
    });

  updateAcceptTos = () =>
    this.setState({
      acceptTos: !this.state.acceptTos,
      signupStatus: { type: "NONE", message: "" }
    });

  handleSubmit = async event => {
    event.preventDefault();
    const {
      email,
      password,
      confirmPassword,
      acceptPrivacy,
      acceptTos,
      captchaPassed
    } = this.state;

    if (!emailIsValid(email))
      return this.setState({
        signupStatus: {
          type: "INVALID",
          message: "Please enter a valid email address."
        }
      });

    if (password !== confirmPassword)
      return this.setState({
        signupStatus: {
          type: "INVALID",
          message: "Passwords did not match."
        }
      });

    if (!passwordIsValid(password))
      return this.setState({
        signupStatus: {
          type: "INVALID",
          message: "Passwords must be at least 6 characters long."
        }
      });

    if (!acceptPrivacy)
      return this.setState({
        signupStatus: {
          type: "INVALID",
          message: "You must acknowledge that you've read the Privacy Policy."
        }
      });

    if (!acceptTos)
      return this.setState({
        signupStatus: {
          type: "INVALID",
          message: "You must acknowledge that you've read the Terms of Service."
        }
      });

    if (process.env.NODE_ENV === "development" || captchaPassed)
      return this.setState(
        {
          signupStatus: { type: "SENDING", message: "Signing up..." }
        },
        this.handleVerifiedSignup
      );

    return this.setState(
      {
        signupStatus: { type: "VERIFYING", message: "Verifying..." }
      },
      () => {
        if (recaptchaRef.current != null) {
          recaptchaRef.current.execute();
        } else {
          return this.handleCaptchaFailure();
        }
      }
    );
  };

  handleVerifiedSignup = async () => {
    const { login } = this.props;
    const { email, username, password } = this.state;
    const [error, resp] = await Api.signUp(username, email, password);

    if (error)
      return this.setState({
        signupStatus: {
          type: "FAILURE",
          message: error.message
        }
      });

    const { jwt, user } = resp;

    return this.setState(
      {
        signupStatus: {
          type: "SUCCESS",
          message: "New Account Created! Redirecting."
        }
      },
      () => setTimeout(() => login(jwt, user, true), 3000)
    );
  };

  /**
   * @sig string => void
   */
  handleCaptchaVerify = responseToken =>
    this.setState(
      {
        signupStatus: { type: "SENDING", message: "Signing up..." },
        captchaPassed: true
      },
      async () => {
        const [error] = await Api.captchaVerify(responseToken);

        if (error) {
          return this.setState({
            signupStatus: {
              type: "FAILURE",
              message: error.message
            }
          });
        }

        await this.handleVerifiedSignup();
      }
    );

  handleCaptchaFailure = () =>
    this.setState({
      signupStatus: {
        type: "FAILURE",
        message: "Could not complete verification."
      }
    });

  get statusMessage() {
    const { signupStatus } = this.state;

    if (signupStatus.message == null) return null;
    const { message } = signupStatus;
    if (signupStatus.type === "SENDING")
      return (
        <Typography component="h2" variant="subtitle1">
          {message}
        </Typography>
      );

    if (signupStatus.type === "INVALID" || signupStatus.type === "FAILURE")
      return <ErrorMessage>{message}</ErrorMessage>;

    if (signupStatus.type === "SUCCESS")
      return <SuccessMessage>{message}</SuccessMessage>;

    return null;
  }

  /**
   *
   * @sig "NONE" | "PRIVACY" | "TOS" => void
   */
  setModal = modal => {
    this.setState({ modal });
  };

  render() {
    const { classes } = this.props;
    const {
      email,
      username,
      password,
      confirmPassword,
      acceptPrivacy,
      acceptTos,
      signupStatus,
      modal
    } = this.state;

    const showForm = signupStatus.type !== "SUCCESS";
    const showSubmitButton = showForm && signupStatus.type !== "SENDING";

    return (
      <div>
        <Dialog
          open={modal !== "NONE"}
          onClose={() => this.setModal("NONE")}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {modal === "PRIVACY" ? (
            <PrivacyPolicyDialog handleClose={() => this.setModal("NONE")} />
          ) : (
            <TermsOfServiceDialog handleClose={() => this.setModal("NONE")} />
          )}
        </Dialog>
        <AppHeader />
        <FullScreenCard>
          <Typography component="h1" variant="h5">
            Create New Account
          </Typography>
          <Typography component="p" variant="body1">
            You must create an account to begin using waterfall.
          </Typography>
          {showForm ? (
            <form className={classes.form} onSubmit={this.handleSubmit}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="email">Email Address</InputLabel>
                <Input
                  id="email"
                  name="email"
                  autoComplete="email"
                  type="email"
                  autoFocus
                  value={email}
                  onChange={this.updateEmail}
                />
              </FormControl>
              <Typography component="p" variant="caption">
                <em>
                  Sign up with your Inner Circle email address to receive a
                  discount!
                </em>
              </Typography>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="username">Username</InputLabel>
                <Input
                  id="username"
                  name="username"
                  autoComplete="username"
                  value={username}
                  onChange={this.updateUsername}
                />
              </FormControl>
              <Typography component="p" variant="caption">
                <em>Username is case-sensitive</em>
              </Typography>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="password">Password</InputLabel>
                <Input
                  name="password"
                  type="password"
                  id="password"
                  value={password}
                  onChange={this.updatePassword}
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="confirmPassword">
                  Confirm Password
                </InputLabel>
                <Input
                  name="confirmPassword"
                  type="password"
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={this.updateConfirmPassword}
                />
              </FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={acceptPrivacy}
                    onChange={this.updateAcceptPrivacy}
                    value="acceptPrivacy"
                  />
                }
                label={
                  <label htmlFor={"sdf"}>
                    I have read and understand the{" "}
                    <Link to="/signup" onClick={() => this.setModal("PRIVACY")}>
                      Privacy Policy
                    </Link>
                  </label>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={acceptTos}
                    onChange={this.updateAcceptTos}
                    value="acceptPrivacy"
                  />
                }
                labelStyle={{ zIndex: 3 }}
                label={
                  <label htmlFor={"sdf"}>
                    I have read and agree to the{" "}
                    <Link to="/signup" onClick={() => this.setModal("TOS")}>
                      Terms of Service
                    </Link>
                  </label>
                }
              />
              {showSubmitButton ? (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Ok
                </Button>
              ) : null}
            </form>
          ) : null}
          {this.statusMessage}
          {signupStatus.type === "SENDING" ||
          signupStatus.type === "SUCCESS" ? (
            <CircularProgress size={20} />
          ) : null}
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={recaptchaKey}
            onChange={this.handleCaptchaVerify}
            onErrored={this.handleCaptchaFailure}
            onExpired={this.handleCaptchaFailure}
          />
        </FullScreenCard>
      </div>
    );
  }
}

const ContextedSignUp = props => (
  <AppContext.Consumer>
    {ctx => <Signup {...props} {...ctx} />}
  </AppContext.Consumer>
);

export default withStyles(styles)(ContextedSignUp);
