import * as R from "ramda";
import * as RF from "./utils/RF";
import axios from "axios";
import env from "./Env";
import myURL from "./utils/myURL";

const baseURL = env.REACT_APP_REST_API_HOST;

const errors = {
  AuthorizationError: 0,
  UnexpectedResponse: 1,
  ForgotPasswordFailed: 2,
  ResetPasswordFailed: 3,
  SignUpFailed: 4,
  CaptchaVerifyFailed: 5,
  UpdateCardFailed: 6
};

const noAuthApi = axios.create({
  baseURL,
  timeout: 10000,
  // Don't throw on successful requests
  validateStatus: () => true
});

/**
 * @sig (string, object) => ErrorResponse
 */
const error = (type, message, payload = {}) => ({
  type,
  message,
  payload
});

/**
 * @sig object => OkResponse
 */
const ok = R.identity;

/**
 * @sig (string, string) => EitherResponse
 */
const login = async (identifier, password) => {
  const resp = await noAuthApi.post("/auth/local", {
    identifier,
    password
  });

  if (resp.status === 400) {
    const message = RF.propOrDefault(
      "message",
      "Could not authenticate to Backend",
      resp.data
    );

    return [error(errors.AuthorizationError, message)];
  }

  const payload = R.applySpec({
    jwt: R.prop("jwt"),
    user: R.prop("user")
  })(resp.data);

  if (RF.objHasAnyNullVal(payload))
    return [
      error(
        errors.UnexpectedResponse,
        "Login response did not contain the expected info",
        resp.data
      ),
      null
    ];

  return [null, ok(payload)];
};

/**
 * @sig string => EitherResponse
 */
const forgotPassword = async email => {
  const resp = await noAuthApi.post("/auth/forgot-password", {
    email,
    url: `${myURL}/reset-password`
  });

  if (resp.status !== 200)
    return [
      error(
        errors.ForgotPasswordFailed,
        "Could not send forgot password email",
        resp.data
      )
    ];

  return [null, ok(resp.data)];
};

/**
 * @sig (string, string, string) => EitherResponse
 */
const resetPassword = async (code, password, passwordConfirmation) => {
  const resp = await noAuthApi.post("/auth/reset-password", {
    code,
    password,
    passwordConfirmation
  });

  if (resp.status !== 200)
    return [
      error(
        errors.ResetPasswordFailed,
        RF.propOrDefault("message", "Could not reset password", resp.data)
      ),
      resp.data
    ];

  return [null, ok(resp.data)];
};

/**
 * @sig (string, string, string) => EitherResponse
 */
const signUp = async (username, email, password) => {
  const resp = await noAuthApi.post("/auth/local/register", {
    username,
    email,
    password
  });

  if (resp.status !== 200) {
    if (typeof resp.data.message === "string")
      return [
        error(
          errors.SignUpFailed,
          typeof resp.data.message === "string"
            ? resp.data.message
            : "There was an error signing up",
          resp.data
        )
      ];
  }

  const retResp = R.applySpec({
    jwt: R.prop("jwt"),
    user: R.prop("user")
  })(resp.data);

  if (RF.objHasAnyNullVal(retResp))
    return [
      error(
        errors.UnexpectedResponse,
        "Sign up response had unexpected info",
        resp.data
      )
    ];

  return [null, ok(retResp)];
};

/**
 * @sig string => EitherResponse
 */
const captchaVerify = async token => {
  const resp = await noAuthApi.post("/usersubscriptions/verifyCaptcha", {
    token
  });

  if (resp.status !== 200 || !resp.data.success)
    return [
      error(errors.CaptchaVerifyFailed, "Could not verify signup", resp.data)
    ];

  return [null, ok(resp.data)];
};

/**
 * @sig string => AuthApi
 */
const makeAuthedApi = token => {
  const authApi = axios.create({
    baseURL,
    timeout: 10000,
    validateStatus: () => true,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  /**
   * @sig () => EitherResponse
   */
  const authUserHasSubscription = async () => {
    const resp = await authApi.get("/usersubscriptions/byUser");

    return [null, ok(resp.status === 200)];
  };

  /**
   * @sig () => EitherResponse
   */
  const authUserHasInnerCircle = async () => {
    const resp = await authApi.get("/usersubscriptions/icSub");

    return [null, ok(resp.data.ok)];
  };

  /**
   * @sig () => EitherResponse
   */
  const cancelSubscription = async subId => {
    const resp = await authApi.post(`/usersubscriptions/${subId}/cancel`);

    return [null, ok(resp.data.ok === true)];
  };

  /**
   * @sig () => EitherResponse
   */
  const getSubscription = async () => {
    const resp = await authApi.get("/usersubscriptions/byUser");

    return [
      null,
      ok(
        resp.status === 200
          ? resp.data
          : {
              id: 0,
              sub_id: "",
              data: {
                cancel_at_period_end: false,
                current_period_end: 2147483648
              }
            }
      )
    ];
  };

  /**
   * @sig string => EitherResponse
   */
  // eslint-disable-next-line no-unused-vars
  const updateCardInfo = async token => {
    const resp = await authApi.post("/usersubscriptions/updateCardInfo", {
      token
    });

    return resp.status !== 200
      ? [error(errors.UpdateCardFailed, "Failed to update card", resp.data)]
      : [null, ok(resp.data)];
  };

  /**
   * @sig () => EitherResponse
   */
  const getUserInfo = async () => {
    const resp = await authApi.get("/usersubscriptions/getStripeInfo");

    return [null, ok(resp.status === 200 ? resp.data : null)];
  };

  return {
    authUserHasSubscription,
    authUserHasInnerCircle,
    cancelSubscription,
    getSubscription,
    updateCardInfo,
    getUserInfo
  };
};

export {
  login,
  forgotPassword,
  resetPassword,
  signUp,
  captchaVerify,
  makeAuthedApi
};
