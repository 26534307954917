import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography/Typography";

const useStyles = makeStyles(theme => ({
  successMsg: {
    color: "#356954",
    fontStyle: "italic",
    textAlign: "center",
    paddingTop: theme.spacing.unit
  }
}));

const SuccessMessage = props => {
  const classes = useStyles();
  return (
    <Typography
      component="h2"
      variant="subtitle1"
      className={classes.successMsg}
    >
      {props.children}
    </Typography>
  );
};

export default SuccessMessage;
