import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography/Typography";

const useStyles = makeStyles(theme => ({
  errorMsg: {
    color: "#642a1c",
    fontStyle: "italic",
    textAlign: "center",
    paddingTop: theme.spacing.unit
  }
}));

const ErrorMessage = props => {
  const classes = useStyles();
  return (
    <Typography component="h2" variant="subtitle1" className={classes.errorMsg}>
      {props.children}
    </Typography>
  );
};

export default ErrorMessage;
