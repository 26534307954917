import * as Api from "../Api";
import * as R from "ramda";
import * as React from "react";
import { AppContext } from "../App";
import { makeStyles } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button/Button";
import ErrorMessage from "../design-system/ErrorMessage";
import FormControl from "@material-ui/core/FormControl/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Input from "@material-ui/core/Input/Input";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import Switch from "@material-ui/core/Switch/Switch";
import Typography from "@material-ui/core/Typography/Typography";

const actionTypes = {
  SetUserName: 0,
  SetPassword: 1,
  SetRememberMe: 2,
  SetError: 3
};

const Signin = props => {
  const classes = makeStyles(theme => ({
    avatar: {
      margin: theme.spacing.unit,
      backgroundColor: theme.palette.secondary.main
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing.unit
    },
    submit: {
      marginTop: theme.spacing.unit * 3
    }
  }))();

  const dispatchType = type => ({ target: { value } }) =>
    props.dispatch({ type, payload: value });

  return (
    <>
      <Typography component="h1" variant="h5">
        Sign in
      </Typography>
      {props.errorMsg.length > 0 ? (
        <ErrorMessage>{props.errorMsg}</ErrorMessage>
      ) : null}
      <form className={classes.form} onSubmit={props.submit}>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="username">Username or Email</InputLabel>
          <Input
            id="username"
            name="username"
            autoComplete="username"
            autoFocus
            value={props.username}
            onChange={dispatchType(actionTypes.SetUserName)}
          />
        </FormControl>
        <Typography component="p" variant="caption">
          <em>Username is case-sensitive</em>
        </Typography>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="password">Password</InputLabel>
          <Input
            name="password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={props.pass}
            onChange={dispatchType(actionTypes.SetPassword)}
          />
        </FormControl>
        <FormControlLabel
          control={
            <Switch
              value="remember"
              checked={props.rememberMe}
              onChange={dispatchType(actionTypes.SetRememberMe)}
            />
          }
          label="Remember me"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Sign in
        </Button>
      </form>
    </>
  );
};

const SignInCtr = props => {
  const ctx = React.useContext(AppContext);

  const reducer = (state, { type, payload }) => {
    switch (type) {
      case actionTypes.SetUserName:
        return { ...state, errorMsg: "", username: payload };

      case actionTypes.SetPassword:
        return { ...state, errorMsg: "", pass: payload };

      case actionTypes.SetRememberMe:
        return { ...state, errorMsg: "", rememberMe: payload };

      case actionTypes.SetError:
        return { ...state, errorMsg: payload };
    }
  };

  const [state, dispatch] = React.useReducer(reducer, {
    username: "",
    pass: "",
    rememberMe: false,
    errorMsg: ""
  });

  const submit = async event => {
    event.preventDefault();
    const [error, resp] = await Api.login(state.username, state.pass);

    if (error) {
      return dispatch({ type: actionTypes.SetError, payload: error.message });
    }

    const onAuthNav = R.path(["location", "state", "onAuth"], props);

    ctx.login(resp.jwt, resp.user, state.rememberMe);

    if (onAuthNav) {
      props.history.push(onAuthNav);
    }
  };

  return <Signin {...state} dispatch={dispatch} submit={submit} />;
};

export default withRouter(SignInCtr);
