import Env from "../Env";
import myURL from "../utils/myURL";

const {
  REACT_APP_STRIPE_API_KEY,
  REACT_APP_STRIPE_BASE_PRODUCT_KEY,
  REACT_APP_STRIPE_DISCOUNT_PRODUCT_KEY
} = Env;

const successUrl = `${myURL}/payment-success`;
const cancelUrl = `${myURL}/payment-cancel`;

const stripeConnection = (() => {
  const stripe = Stripe(REACT_APP_STRIPE_API_KEY);

  /**
   * @sig string => async string => maybe error
   */
  const checkoutForProduct = plan => async userId =>
    await stripe.redirectToCheckout({
      items: [
        {
          plan,
          quantity: 1
        }
      ],
      successUrl,
      cancelUrl,
      clientReferenceId: userId
    });

  const checkoutForBaseProduct = checkoutForProduct(
    REACT_APP_STRIPE_BASE_PRODUCT_KEY
  );

  const checkoutForDiscountedProduct = checkoutForProduct(
    REACT_APP_STRIPE_DISCOUNT_PRODUCT_KEY
  );

  return {
    checkoutForBaseProduct,
    checkoutForDiscountedProduct
  };
})();

export default stripeConnection;
