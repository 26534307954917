import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography/Typography";

const useStyles = makeStyles(theme => ({
  bodyText: {
    marginBottom: theme.spacing.unit * 2
  }
}));

const PrivacyPolicyContent = () => {
  const classes = useStyles();

  return (
    <div>
      <Typography component="p" variant="h6">
        Information Collection
      </Typography>
      <Typography component="p" variant="body1" className={classes.bodyText}>
        In accordance with standard web practices, our website maintains log
        files on the web server to record visitors' IP addresses, browser
        types, referring pages, and visit times. Additionally, when a user
        registers for our service, their email address is securely stored and
        linked to their account.
      </Typography>
      <Typography component="p" variant="h6">
        Utilization of Information
      </Typography>
      <Typography component="p" variant="body1" className={classes.bodyText}>
        The content of web server logs is strictly utilized for service
        monitoring and support purposes. Email addresses associated with user
        accounts are solely used for facilitating service functionalities such
        as password recovery and login. Under no circumstances will email
        addresses be sold, rented, or leased to third parties.
      </Typography>
      <Typography component="p" variant="h6">
        Visitor Choices
      </Typography>
      <Typography component="p" variant="body1" className={classes.bodyText}>
        Upon unsubscribing from our service, users will cease to receive any
        emails at the address provided in their user account.
      </Typography>
      <Typography component="p" variant="h6">
        Account Removal
      </Typography>
      <Typography component="p" variant="body1" className={classes.bodyText}>
        To remove your Waterfall account, you need to provide your username and
        email address associated with the account. Please send a message to the
        following email address with the subject "Account Removal":
        radiantstreamingapp@gmail.com. Upon receipt of your request, our team
        will promptly proceed with the necessary steps to deactivate your
        account and remove your personal information from our systems. Please
        note that your subscription will be terminated immediately.
      </Typography>
      <Typography component="p" variant="h6">
        Cookies
      </Typography>
      <Typography component="p" variant="body1" className={classes.bodyText}>
        While our service does not utilize first-party cookies, it may generate
        third-party cookies through platforms and solutions integrated with the
        service.
      </Typography>
    </div>
  );
};

export default PrivacyPolicyContent;
