import * as R from "ramda";
import * as React from "react";
import { AppContext } from "../App";
import { makeStyles } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Button from "@material-ui/core/es/Button/Button";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline";
import Divider from "@material-ui/core/Divider/Divider";
import Drawer from "@material-ui/core/Drawer/Drawer";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Link from "react-router-dom/es/Link";
import List from "@material-ui/core/List/List";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import Typography from "@material-ui/core/Typography/Typography";
import classNames from "classnames";

const drawerWidth = 240;

const Dashboard = props => {
  const showPersistentDrawer = window.innerWidth > 600;

  const ctx = React.useContext(AppContext);
  const [userSub, setUserSub] = React.useState(null);
  const [stripeUserInfo, setStripeUserInfo] = React.useState(null);
  const [open, setOpen] = React.useState(window.innerWidth > 600);

  const subExpireTime =
    userSub != null && userSub.data != null && userSub.data.cancel_at_period_end
      ? userSub.data.current_period_end
      : null;

  const subExpirationPassed =
    subExpireTime != null && new Date(subExpireTime * 1000) < new Date();

  const classes = makeStyles(theme => ({
    link: {
      marginTop: theme.spacing.unit,
      textDecoration: "none",
      color: "black"
    },
    linkText: {
      textAlign: "center"
    },
    linkTextActive: {
      fontWeight: "bold"
    },
    root: {
      display: "flex"
    },
    toolbarIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0 8px",
      ...theme.mixins.toolbar
    },
    menuButton: {
      marginLeft: 12,
      marginRight: 36
    },
    menuButtonHidden: {
      display: "none"
    },
    title: {
      flexGrow: 1
    },
    drawerPaper: {
      position: "relative",
      whiteSpace: "nowrap",
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    drawerPaperClose: {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      width: theme.spacing.unit * 7,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing.unit * 9
      }
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing.unit * 3,
      height: "100vh",
      overflow: "auto"
    },
    topBarSpacer: {
      marginTop: 64
    },
    bodyContent: {
      marginBottom: theme.spacing.unit,
      textAlign: "center"
    },
    appBar: {
      marginLeft: drawerWidth,
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`
      }
    }
  }))();

  const getSub = async () => {
    if (ctx.authApi != null) {
      const { authApi } = ctx;
      const [, sub] = await authApi.getSubscription();
      setUserSub(sub);

      const [, userInfo] = await authApi.getUserInfo();
      setStripeUserInfo(userInfo);
    }
  };

  React.useEffect(
    () => {
      getSub();
    },
    [R.path(["location", "state", "refresh"], props)]
  );

  const rp = {
    subExpireTime,
    showPersistentDrawer,
    userInfo: stripeUserInfo,
    userSub: userSub
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      {subExpireTime == null ? null : (
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            Your subscription {subExpirationPassed ? "expired" : "will expire"}{" "}
            on {new Date(subExpireTime * 1000).toLocaleDateString()} at{" "}
            {new Date(subExpireTime * 1000).toLocaleTimeString()}
          </Toolbar>
        </AppBar>
      )}
      <Drawer
        variant={showPersistentDrawer ? "permanent" : "temporary"}
        anchor="left"
        classes={{
          paper: classNames(
            classes.drawerPaper,
            !open && classes.drawerPaperClose
          )
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton
            onClick={open ? () => setOpen(false) : () => setOpen(true)}
          >
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        {open ? (
          <>
            <Divider />
            <List>
              {[
                <Typography
                  component={"h2"}
                  variant={"subtitle1"}
                  key={"0"}
                  className={classNames(classes.linkText)}
                >
                  <Link to="/change-password" className={classes.link}>
                    Change Password
                  </Link>
                </Typography>,
                <Typography
                  key={"1"}
                  component={"h2"}
                  variant={"subtitle1"}
                  className={classNames(classes.linkText)}
                >
                  <Link to="/cancel-subscription" className={classes.link}>
                    Cancel Subscription
                  </Link>
                </Typography>,
                <Typography
                  key={"2"}
                  component={"h2"}
                  variant={"subtitle1"}
                  className={classNames(classes.linkText)}
                >
                  <Link to="/update-card" className={classes.link}>
                    Update Card Info
                  </Link>
                </Typography>
              ]}
            </List>
            <Divider />
            <List>
              {
                <Typography
                  key={"0"}
                  component={"h2"}
                  variant={"subtitle1"}
                  onClick={() => {
                    ctx.logout();
                  }}
                  className={classes.linkText}
                >
                  <Link to="/" key="2" className={classes.link}>
                    Sign Out
                  </Link>
                </Typography>
              }
            </List>
          </>
        ) : null}
      </Drawer>
      <div className={classes.content}>
        {subExpireTime == null ? null : (
          <div className={classes.topBarSpacer} />
        )}
        {showPersistentDrawer ? null : (
          <Button
            variant="outlined"
            onClick={() => setOpen(!open)}
            color="primary"
            className={classes.bodyContent}
          >
            Menu
          </Button>
        )}
        {props.children(rp)}
      </div>
    </div>
  );
};

export default withRouter(Dashboard);
