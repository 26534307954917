import * as React from "react";
import { AppContext } from "../App";
import { BASE_RATE, IC_DISCOUNT_RATE } from "../utils/constants";
import { Dialog } from "@material-ui/core";
import { Link, Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import AppHeader from "../components/AppHeader";
import Button from "@material-ui/core/Button/Button";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import CreditCard from "@material-ui/icons/CreditCard";
import ErrorMessage from "../design-system/ErrorMessage";
import FullScreenCard from "../design-system/FullScreenCard";
import InnerCircleHelpDialog from "../components/IcHelpDialog";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import StripeConnection from "../stripe/StripeConnection";
import Typography from "@material-ui/core/Typography/Typography";

const useStyles = makeStyles(theme => ({
  bodyText: {
    textAlign: "center",
    marginBottom: theme.spacing.unit
  },
  strong: {
    fontWeight: "bold",
    display: "inline"
  },
  submit: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 2
  },
  creditCardIcon: {
    marginRight: theme.spacing.unit
  },
  helpLine: {
    display: "flex",
    marginBottom: theme.spacing.unit
  },
  helpIcon: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing.unit
  }
}));

const Subscribe = props => {
  const classes = useStyles();
  const ctx = React.useContext(AppContext);
  if (ctx.authApi == null) return <Redirect to="/error" />;

  const [redirectErrorMessage, setRedirectErrorMessage] = React.useState();
  const [showIcHelpDialog, setShowIcHelpDialog] = React.useState(false);

  const [userIsInnerCircleMember, setUserIsInnerCircleMember] = React.useState(
    "maybe"
  );

  const innerCircleQuery = async () => {
    const [, isIc] = await ctx.authApi.authUserHasInnerCircle();
    setUserIsInnerCircleMember(isIc);
  };

  React.useEffect(() => {
    ctx.rememberMe({ token: props.jwt, user: props.user });
    innerCircleQuery();
  }, []);

  const makeCheckoutButton = onClick => (
    <Button
      fullWidth
      variant="contained"
      color="primary"
      onClick={async () => {
        const result = await onClick();

        if (result != null && result.error != null)
          setRedirectErrorMessage(result.error.message);
      }}
      className={classes.submit}
    >
      <CreditCard className={classes.creditCardIcon} />
      Pay Now
    </Button>
  );

  const priceDescriptionAndCheckoutButton = (() => {
    switch (userIsInnerCircleMember) {
      case "maybe":
        return (
          <Typography component="h2" variant="subtitle1">
            <CircularProgress size={20} /> Checking pricing...
          </Typography>
        );

      case true:
        return (
          <>
            <Typography component="p" className={classes.bodyText}>
              Thank you for being an Inner Circle member! As thanks for your
              continued support, we would like to extend you a special
              discounted fee of{" "}
              {
                <Typography component="span" className={classes.strong}>
                  {IC_DISCOUNT_RATE}/mo
                </Typography>
              }{" "}
              for waterfall. You will be automatically re-billed every month
              until you cancel your subscription.
            </Typography>
            {makeCheckoutButton(async () =>
              StripeConnection.checkoutForDiscountedProduct(
                props.user.id.toString()
              )
            )}
            <Link to="/" onClick={ctx.logout}>
              I don't want to subscribe yet
            </Link>
          </>
        );

      case false:
        return (
          <>
            <Dialog
              open={showIcHelpDialog}
              onClose={() => setShowIcHelpDialog(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <InnerCircleHelpDialog
                email={props.user.email}
                handleClose={() => setShowIcHelpDialog(false)}
              />
            </Dialog>
            <Typography component="p" className={classes.bodyText}>
              The subscription fee is{" "}
              {
                <Typography component="span" className={classes.strong}>
                  {BASE_RATE}/mo.
                </Typography>
              }{" "}
              You will be automatically re-billed every month until you cancel
              your subscription.
            </Typography>
            {makeCheckoutButton(async () =>
              StripeConnection.checkoutForBaseProduct(props.user.id.toString())
            )}
            <Link to="#" onClick={() => setShowIcHelpDialog(true)}>
              <div className={classes.helpLine}>
                <LiveHelpIcon className={classes.helpIcon} fontSize="small" />{" "}
                How can I get a discount?
              </div>
            </Link>
            <Link to="/" onClick={ctx.logout}>
              I don't want to subscribe yet
            </Link>
          </>
        );
    }

    return (
      <Typography component="h2" variant="subtitle1">
        Checking pricing...
      </Typography>
    );
  })();

  return (
    <div>
      <AppHeader />
      <FullScreenCard>
        <Typography component="h1" variant="h5" className={classes.bodyText}>
          {`Welcome, ${props.user.username}!`}
        </Typography>
        <Typography
          component="h2"
          variant="subtitle1"
          className={classes.bodyText}
        >
          You have an account but still need to subscribe
        </Typography>
        {priceDescriptionAndCheckoutButton}
        {redirectErrorMessage ? (
          <ErrorMessage>{redirectErrorMessage}</ErrorMessage>
        ) : null}
      </FullScreenCard>
    </div>
  );
};

export default Subscribe;
