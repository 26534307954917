import * as React from "react";
import ErrorMessage from "./design-system/ErrorMessage";
import FullScreenCard from "./design-system/FullScreenCard";
import Typography from "@material-ui/core/Typography/Typography";

const ErrorScreen = () => (
  <FullScreenCard>
    <Typography component="h1" variant="h5">
      Oops
    </Typography>
    <ErrorMessage>Something went wrong. Sorry about that!</ErrorMessage>
  </FullScreenCard>
);

export default ErrorScreen;
