import * as Api from "../Api";
import * as R from "ramda";
import * as React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { setFromDomEvent } from "../utils/hooks";
import AppHeader from "../components/AppHeader";
import Button from "@material-ui/core/Button/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog/Dialog";
import ErrorMessage from "../design-system/ErrorMessage";
import FormControl from "@material-ui/core/FormControl/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import FullScreenCard from "../design-system/FullScreenCard";
import Input from "@material-ui/core/Input/Input";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import PrivacyPolicyDialog from "../components/PrivacyPolicyDialog";
import SuccessMessage from "../design-system/SuccessMessage";
import TermsOfServiceDialog from "../components/TermsOfServiceDialog";
import Typography from "@material-ui/core/Typography/Typography";

const useStyles = makeStyles(theme => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  }
}));

const ClaimAccount = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const classes = useStyles();
  const [email, setEmail] = React.useState(urlParams.get("email") || "");
  const [acceptPrivacy, setAcceptPrivacy] = React.useState(false);
  const [acceptTos, setAcceptTos] = React.useState(false);
  const [modal, setModal] = React.useState("NONE");
  const [emailSendStatus, setEmailSendStatus] = React.useState("NONE");

  const setFormInput = setter => e => {
    setEmailSendStatus("NONE");
    setFromDomEvent(setter, e);
  };
  const updateEmail = setFormInput(setEmail);
  const handleSubmit = async event => {
    event.preventDefault();
    setEmailSendStatus("SENDING");

    const [error] = await Api.forgotPassword(email);

    if (error) return setEmailSendStatus("FAILURE");

    setEmailSendStatus("SUCCESS");
  };

  const showForm = emailSendStatus !== "SUCCESS";
  const showSubmitButton = emailSendStatus !== "SENDING";
  const sendStatus = (() => {
    switch (emailSendStatus) {
      case "NONE":
        return null;

      case "FAILURE":
        return (
          <ErrorMessage>
            Could not find an account for this email address. Are you sure it is
            correct?
          </ErrorMessage>
        );

      case "SUCCESS":
        return (
          <SuccessMessage>{`Account claim successful! Check your inbox at ${email} for access instructions.`}</SuccessMessage>
        );

      case "SENDING":
        return (
          <Typography component="h2" variant="subtitle1">
            Sending...
          </Typography>
        );
    }
  })();

  return (
    <div>
      <Dialog
        open={modal !== "NONE"}
        onClose={() => setModal("NONE")}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {modal === "PRIVACY" ? (
          <PrivacyPolicyDialog handleClose={() => setModal("NONE")} />
        ) : (
          <TermsOfServiceDialog handleClose={() => setModal("NONE")} />
        )}
      </Dialog>
      <AppHeader />
      <FullScreenCard>
        <Typography component="h1" variant="h5">
          Claim Your Account
        </Typography>
        <Typography component="h1" variant="body1">
          Enter your Radiant Records email address to claim your trial Waterfall
          account!
        </Typography>
        {showForm ? (
          <form className={classes.form} onSubmit={handleSubmit}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="email">Email Address</InputLabel>
              <Input
                id="email"
                name="email"
                autoComplete="email"
                type="email"
                autoFocus
                value={email}
                onChange={updateEmail}
              />
            </FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={acceptPrivacy}
                  onChange={() => setAcceptPrivacy(R.not)}
                  id="acceptPrivacy"
                />
              }
              label={
                <label htmlFor="acceptPrivacy">
                  I have read and understand the{" "}
                  <Link
                    to=""
                    onClick={e => {
                      e.preventDefault();
                      setModal("PRIVACY");
                    }}
                  >
                    Privacy Policy
                  </Link>
                </label>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={acceptTos}
                  onChange={() => setAcceptTos(R.not)}
                  id="acceptTos"
                />
              }
              labelStyle={{ zIndex: 3 }}
              label={
                <label htmlFor="acceptTos">
                  I have read and agree to the{" "}
                  <Link
                    to=""
                    onClick={e => {
                      e.preventDefault();
                      setModal("TOS");
                    }}
                  >
                    Terms of Service
                  </Link>
                </label>
              }
            />
            {showSubmitButton ? (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={!acceptPrivacy || !acceptTos}
              >
                Go!
              </Button>
            ) : null}
          </form>
        ) : null}
        {sendStatus}
        {emailSendStatus === "SUCCESS" ? (
          <Typography component={"h2"} variant={"subtitle1"}>
            <Link to="/">Go To Login</Link>
          </Typography>
        ) : null}
      </FullScreenCard>
    </div>
  );
};

export default ClaimAccount;
