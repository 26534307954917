/**
 * RF: Short for Ramda Functions. Common compositions of Ramda functions
 * for wide use throughout the app
 */
import * as R from "ramda";

export const pathOrDefault = R.curry((path, def, o) =>
  R.pipe(
    R.path(path),
    R.defaultTo(def)
  )(o)
);

export const propOrDefault = R.curry((prop, def, o) =>
  R.pipe(
    R.prop(prop),
    R.defaultTo(def)
  )(o)
);

export const objHasAnyNullVal = R.pipe(
  R.values,
  R.any(R.isNil)
);
