import * as React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import AlternateEmail from "@material-ui/icons/AlternateEmail";
import AppHeader from "../components/AppHeader";
import Email from "@material-ui/icons/Email";
import FullScreenCard from "../design-system/FullScreenCard";
import Typography from "@material-ui/core/Typography/Typography";

const useStyles = makeStyles(theme => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  },
  bodyText: {
    textAlign: "center",
    marginBottom: theme.spacing.unit
  },
  link: {
    marginTop: theme.spacing.unit
  }
}));

const ResetPassword = () => {
  const classes = useStyles();

  return (
    <div>
      <AppHeader />
      <FullScreenCard>
        <Typography component="h1" variant="h5">
          Support
        </Typography>
        <Typography component="p" className={classes.bodyText}>
          Sorry to hear that you're having trouble with waterfall! You can
          contact us via one of the methods listed below, and we will be in
          touch with you within 24 hours to help wash your troubles downstream.
        </Typography>
        <Typography component="p" className={classes.bodyText}>
          <Email />{" "}
          <a href="mailto:radiantstreamingapp@gmail.com">
            radiantstreamingapp@gmail.com
          </a>
        </Typography>
        <Typography component="p" className={classes.bodyText}>
          <AlternateEmail />{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/radiantrecord"
          >
            radiantrecord
          </a>
        </Typography>
        <Typography
          component={"h2"}
          variant={"subtitle1"}
          className={classes.link}
        >
          <Link to="/">Go Back</Link>
        </Typography>
      </FullScreenCard>
    </div>
  );
};

export default ResetPassword;
