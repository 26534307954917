import * as React from "react";
import { AppContext } from "../App";
import { Redirect } from "react-router-dom";

const PaymentCancel = () => {
  const ctx = React.useContext(AppContext);
  React.useEffect(() => {
    ctx.logout();
  });

  return <Redirect to="/" />;
};

export default PaymentCancel;
