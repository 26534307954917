import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import AccountDeletionContent from "../components/AccountDeletionContent";
import AppHeader from "../components/AppHeader";
import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline";
import Paper from "@material-ui/core/Paper/Paper";
import Typography from "@material-ui/core/es/Typography/Typography";

const useStyles = makeStyles(theme => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing.unit * 6,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`
  },
  header: {
    marginBottom: theme.spacing.unit * 2
  }
}));

const AccountDeletionScreen = () => {
  const classes = useStyles();

  return (
    <div>
      <AppHeader />
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4" className={classes.header}>
            Account Deletion
          </Typography>
          <AccountDeletionContent />
        </Paper>
      </main>
    </div>
  );
};

export default AccountDeletionScreen;
