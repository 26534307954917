/* eslint-disable no-console */
import "./bootstrap";
import * as Api from "./Api";
import * as React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import NotSignedInRoutes from "./NotSignedInRoutes";
import NotSubscribedRoutes from "./NotSubscribedRoutes";
import SubscribedRoutes from "./SubscribedRoutes";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";

const AppContext = React.createContext({
  login: () => {},
  rememberMe: () => {},
  forgetMe: () => {},
  logout: () => {},
  gotUserSubscription: () => {},
  authApi: null
});

const persistedState = JSON.parse(localStorage.getItem("AppState") || "{}");

const theme = {
  typography: {
    useNextVariants: true
  },
  palette: {
    secondary: {
      light: "#AFAF5F",
      main: "#A7A84F",
      dark: "#989948",
      contrastText: "#fff"
    },
    primary: {
      light: "#445551",
      main: "#324440",
      dark: "#2E3E3B",
      contrastText: "#fff"
    }
  }
};

const App = () => {
  const [state, setState] = React.useState(persistedState);

  const rememberMe = state => {
    localStorage.setItem("AppState", JSON.stringify(state));
  };
  const forgetMe = () => {
    localStorage.clear();
  };

  const login = (token, user, remember) => {
    const updatedState = {
      token,
      user
    };

    if (remember) rememberMe(updatedState);
    setState(updatedState);
  };

  const logout = () => {
    forgetMe();
    setState({
      user: undefined,
      token: undefined
    });
  };

  const gotUserSubscription = () => {
    setState({
      ...state,
      user: {
        ...state.user,
        userSubscription: true
      }
    });
    forgetMe();
  };

  const userNotSignedIn = state.user == null;
  const userSubscribed =
    state.user != null &&
    (state.user.isVip || state.user.userSubscription != null);

  const routes = (() => {
    if (userNotSignedIn) return <NotSignedInRoutes />;
    if (!userSubscribed)
      return <NotSubscribedRoutes user={state.user} token={state.token} />;

    return <SubscribedRoutes user={state.user} />;
  })();

  return (
    <ThemeProvider
      theme={{
        ...createMuiTheme(theme)
      }}
    >
      <AppContext.Provider
        value={{
          login,
          logout,
          forgetMe,
          rememberMe,
          gotUserSubscription,
          authApi: state.token == null ? null : Api.makeAuthedApi(state.token),
          user: state.user
        }}
      >
        <Router>{routes}</Router>
      </AppContext.Provider>
    </ThemeProvider>
  );
};

export { AppContext };
export default App;
