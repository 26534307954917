import * as React from "react";
import Button from "@material-ui/core/Button/Button";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import PrivacyPolicyContent from "./PrivacyPolicyContent";

const PrivacyPolicyDialog = props => {
  return (
    <>
      <DialogTitle id="alert-dialog-title">Privacy Policy</DialogTitle>
      <DialogContent>
        <PrivacyPolicyContent />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary" variant="contained">
          Ok
        </Button>
      </DialogActions>
    </>
  );
};

export default PrivacyPolicyDialog;
