import * as React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import AppHeader from "../components/AppHeader";
import Dialog from "@material-ui/core/Dialog/Dialog";
import FullScreenCard from "../design-system/FullScreenCard";
import PrivacyPolicyDialog from "../components/PrivacyPolicyDialog";
import Signin from "../components/Signin";
import Typography from "@material-ui/core/Typography/Typography";

const useStyles = makeStyles(theme => ({
  link: {
    marginTop: theme.spacing.unit
  }
}));

const NotSignedInRoot = () => {
  const classes = useStyles();
  const [showPrivacyPolicy, setShowPrivacyPolicy] = React.useState(false);
  return (
    <div>
      <Dialog
        open={showPrivacyPolicy}
        onClose={() => setShowPrivacyPolicy(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <PrivacyPolicyDialog handleClose={() => setShowPrivacyPolicy(false)} />
      </Dialog>
      <AppHeader />
      <FullScreenCard>
        <Signin />
        <Typography
          component={"h2"}
          variant={"subtitle1"}
          className={classes.link}
        >
          New User? <Link to={`/signup`}>Sign Up!</Link>
        </Typography>
        <Typography
          component={"h2"}
          variant={"subtitle1"}
          className={classes.link}
        >
          <Link to={`/forgot-password`}>Forgot Password</Link>
        </Typography>
        <Typography
          component={"h2"}
          variant={"subtitle1"}
          className={classes.link}
        >
          <a href="javascript:;" onClick={() => setShowPrivacyPolicy(true)}>
            Privacy Policy
          </a>
        </Typography>
      </FullScreenCard>
    </div>
  );
};

export default NotSignedInRoot;
