import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button/Button";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Typography from "@material-ui/core/Typography/Typography";

const useStyles = makeStyles(theme => ({
  bodyText: {
    marginBottom: theme.spacing.unit * 2
  }
}));

const TermsOfServiceDialog = props => {
  const classes = useStyles();

  const H = ({ children }) => (
    <Typography component="p" variant="h6">
      {children}
    </Typography>
  );

  const P = ({ children }) => (
    <Typography component="p" variant="body1" className={classes.bodyText}>
      {children}
    </Typography>
  );

  return (
    <>
      <DialogTitle id="alert-dialog-title">Terms of Service</DialogTitle>
      <DialogContent>
        <div>
          <H>Last updated: January 22, 2019</H>

          <P>
            Please read these Terms and Conditions (&quot;Terms&quot;,
            &quot;Terms and Conditions&quot;) carefully before using the
            waterfall streaming app. mobile application (the
            &quot;Service&quot;) operated by Radiant Records (&quot;us&quot;,
            &quot;we&quot;, or &quot;our&quot;).
          </P>

          <P>
            Your access to and use of the Service is conditioned upon your
            acceptance of and compliance with these Terms. These Terms apply to
            all visitors, users and others who wish to access or use the
            Service. By accessing or using the Service you agree to be bound by
            these Terms. If you disagree with any part of the terms then you do
            not have permission to access the Service.
          </P>

          <H>Subscriptions</H>

          <P>
            Some parts of the Service are billed on a subscription basis
            (&quot;Subscription(s)&quot;). You will be billed in advance on a
            recurring and periodic basis (&quot;Billing Cycle&quot;). Billing
            cycles are set on a monthly basis. At the end of each Billing Cycle,
            your Subscription will automatically renew under the exact same
            conditions unless you cancel it or Radiant Records cancels it. You
            may cancel your Subscription renewal either through your online
            account management page or by contacting Radiant Records customer
            support team.
          </P>

          <P>
            A valid payment method, including credit card or Stripe, is required
            to process the payment for your Subscription. You shall provide
            Radiant Records with accurate and complete billing information
            including full name, address, state, zip code, telephone number, and
            a valid payment method information. By submitting such payment
            information, you automatically authorize Radiant Records to charge
            all Subscription fees incurred through your account to any such
            payment instruments.
          </P>

          <P>
            Should automatic billing fail to occur for any reason, Radiant
            Records will issue an electronic invoice indicating that you must
            proceed manually, within a certain deadline date, with the full
            payment corresponding to the billing period as indicated on the
            invoice.
          </P>

          <H>Fee Changes</H>

          <P>
            Radiant Records, in its sole discretion and at any time, may modify
            the Subscription fees for the Subscriptions. Any Subscription fee
            change will become effective at the end of the then-current Billing
            Cycle.
          </P>

          <P>
            Radiant Records will provide you with a reasonable prior notice of
            any change in Subscription fees to give you an opportunity to
            terminate your Subscription before such change becomes effective.
            Your continued use of the Service after the Subscription fee change
            comes into effect constitutes your agreement to pay the modified
            Subscription fee amount.
          </P>

          <H>Refunds</H>

          <P>
            Except when required by law, paid Subscription fees are
            non-refundable.
          </P>

          <H>Accounts</H>

          <P>
            When you create an account with us, you guarantee that you are above
            the age of 18, and that the information you provide us is accurate,
            complete, and current at all times. Inaccurate, incomplete, or
            obsolete information may result in the immediate termination of your
            account on the Service. You are responsible for maintaining the
            confidentiality of your account and password, including but not
            limited to the restriction of access to your computer and/or
            account. You agree to accept responsibility for any and all
            activities or actions that occur under your account and/or password,
            whether your password is with our Service or a third-party service.
            You must notify us immediately upon becoming aware of any breach of
            security or unauthorized use of your account.
          </P>

          <P>
            You may not use as a username the name of another person or entity
            or that is not lawfully available for use, a name or trademark that
            is subject to any rights of another person or entity other than you,
            without appropriate authorization. You may not use as a username any
            name that is offensive, vulgar or obscene.
          </P>

          <H>Intellectual Property</H>

          <P>
            The Service and its original content, features and functionality are
            and will remain the exclusive property of Radiant Records and its
            licensors. The Service is protected by copyright, trademark, and
            other laws of both the United States and foreign countries. Our
            trademarks and trade dress may not be used in connection with any
            product or service without the prior written consent of Radiant
            Records. Links To Other Web Sites
          </P>

          <P>
            Our Service may contain links to third party web sites or services
            that are not owned or controlled by Radiant Records
          </P>

          <P>
            Radiant Records has no control over, and assumes no responsibility
            for the content, privacy policies, or practices of any third party
            web sites or services. We do not warrant the offerings of any of
            these entities/individuals or their websites.
          </P>

          <P>
            You acknowledge and agree that Radiant Records shall not be
            responsible or liable, directly or indirectly, for any damage or
            loss caused or alleged to be caused by or in connection with use of
            or reliance on any such content, goods or services available on or
            through any such third party web sites or services. We strongly
            advise you to read the terms and conditions and privacy policies of
            any third party web sites or services that you visit.
          </P>

          <H>Termination</H>

          <P>
            We may terminate or suspend your account and bar access to the
            Service immediately, without prior notice or liability, under our
            sole discretion, for any reason whatsoever and without limitation,
            including but not limited to a breach of the Terms.
          </P>

          <P>
            If you wish to terminate your account, you may simply discontinue
            using the Service. All provisions of the Terms which by their nature
            should survive termination shall survive termination, including,
            without limitation, ownership provisions, warranty disclaimers,
            indemnity and limitations of liability.
          </P>

          <H>Indemnification</H>

          <P>
            You agree to defend, indemnify and hold harmless Radiant Records and
            its licensee and licensors, and their employees, contractors,
            agents, officers and directors, from and against any and all claims,
            damages, obligations, losses, liabilities, costs or debt, and
            expenses (including but not limited to attorney&#39;s fees),
            resulting from or arising out of a) your use and access of the
            Service, by you or any person using your account and password, or b)
            a breach of these Terms.
          </P>

          <H>Limitation Of Liability</H>

          <P>
            In no event shall Radiant Records, nor its directors, employees,
            partners, agents, suppliers, or affiliates, be liable for any
            indirect, incidental, special, consequential or punitive damages,
            including without limitation, loss of profits, data, use, goodwill,
            or other intangible losses, resulting from (i) your access to or use
            of or inability to access or use the Service; (ii) any conduct or
            content of any third party on the Service; (iii) any content
            obtained from the Service; and (iv) unauthorized access, use or
            alteration of your transmissions or content, whether based on
            warranty, contract, tort (including negligence) or any other legal
            theory, whether or not we have been informed of the possibility of
            such damage, and even if a remedy set forth herein is found to have
            failed of its essential purpose.
          </P>

          <H>Disclaimer</H>

          <P>
            Your use of the Service is at your sole risk. The Service is
            provided on an &quot;AS IS&quot; and &quot;AS AVAILABLE&quot; basis.
            The Service is provided without warranties of any kind, whether
            express or implied, including, but not limited to, implied
            warranties of merchantability, fitness for a particular purpose,
            non-infringement or course of performance.
          </P>

          <P>
            Radiant Records its subsidiaries, affiliates, and its licensors do
            not warrant that a) the Service will function uninterrupted, secure
            or available at any particular time or location; b) any errors or
            defects will be corrected; c) the Service is free of viruses or
            other harmful components; or d) the results of using the Service
            will meet your requirements.
          </P>

          <H>Exclusions</H>

          <P>
            Some jurisdictions do not allow the exclusion of certain warranties
            or the exclusion or limitation of liability for consequential or
            incidental damages, so the limitations above may not apply to you.
            Governing Law
          </P>

          <P>
            These Terms shall be governed and construed in accordance with the
            laws of Tennessee, United States, without regard to its conflict of
            law provisions.
          </P>

          <P>
            Our failure to enforce any right or provision of these Terms will
            not be considered a waiver of those rights. If any provision of
            these Terms is held to be invalid or unenforceable by a court, the
            remaining provisions of these Terms will remain in effect. These
            Terms constitute the entire agreement between us regarding our
            Service, and supersede and replace any prior agreements we might
            have had between us regarding the Service.
          </P>

          <H>Changes</H>

          <P>
            We reserve the right, at our sole discretion, to modify or replace
            these Terms at any time. If a revision is material we will provide
            at least 30 days notice prior to any new terms taking effect. What
            constitutes a material change will be determined at our sole
            discretion.
          </P>

          <P>
            By continuing to access or use our Service after any revisions
            become effective, you agree to be bound by the revised terms. If you
            do not agree to the new terms, you are no longer authorized to use
            the Service.
          </P>

          <H>Contact Us</H>

          <P>
            If you have any questions about these Terms, please contact us at{" "}
            <a href="mailto:radiantstreaming.com">radiantstreaming@gmail.com</a>
          </P>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary" variant="contained">
          Ok
        </Button>
      </DialogActions>
    </>
  );
};

export default TermsOfServiceDialog;
