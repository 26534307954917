import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import Logo from "../waterfall-logo.png";

const useStyles = makeStyles(theme => ({
  link: {
    marginTop: theme.spacing.unit
  },
  header: {
    marginTop: theme.spacing.unit * 2,
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(700 + theme.spacing.unit * 3 * 2)]: {
      width: 700,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  logoImage: {
    display: "block",
    margin: "auto",
    height: "6em"
  }
}));

const AppHeader = () => {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <img
        src={Logo}
        alt="waterfall by Radiant Records"
        className={classes.logoImage}
      />
    </div>
  );
};

export default AppHeader;
