import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button/Button";
import Typography from "@material-ui/core/Typography/Typography";

const useStyles = makeStyles(theme => ({
  bodyText: {
    marginBottom: theme.spacing.unit * 2
  }
}));

const AccountDeletionContent = () => {
  const classes = useStyles();

  return (
    <div>
      <Typography component="p" variant="body1" className={classes.bodyText}>
        Before you go, we want to make sure you understand the consequences of deleting your account.</Typography>
      <Typography component="p" variant="body1" className={classes.bodyText}>
        If you delete your account, you will lose access to your playlists, saved content, and any personal settings. This action is permanent and cannot be undone.
      </Typography>
      <Typography component="p" variant="body1" className={classes.bodyText}>
        To proceed, please send a mail with the username or email address associated with your account to the following email address: <b>radiantstreamingapp@gmail.com</b> or use the button below.
      </Typography>
      <Typography component="p" variant="body1" className={classes.bodyText}>
        Please allow up to 2 working days for your account to be deleted. If you change your mind, you can register at any time to enjoy Waterfall again!
      </Typography>
      <Button
        component="a"
        href="mailto:radiantstreamingapp@gmail.com?subject=Account%20Deletion"
        fullWidth
        color="primary"
      >
        Remove Account
      </Button>
    </div>
  );
};

export default AccountDeletionContent;
