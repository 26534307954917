import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import CancelSubscription from "./components/CancelSubscription";
import ChangePassword from "./components/ChangePassword";
import Dashboard from "./screens/Dashboard";
import DashboardHome from "./components/DashboardHome";
import ErrorScreen from "./Error";
import UpdateCardInfo from "./components/UpdateCardInfo";

const SubscribedRoutes = props => (
  <Dashboard user={props.user}>
    {rp => (
      <Switch>
        <Route
          exact
          path="/"
          render={() => <DashboardHome user={props.user} {...rp} />}
        />
        <Route
          path="/change-password"
          render={() => <ChangePassword user={props.user} {...rp} />}
        />
        <Route
          path="/cancel-subscription"
          render={() => <CancelSubscription user={props.user} {...rp} />}
        />
        <Route
          path="/update-card"
          render={() => <UpdateCardInfo user={props.user} {...rp} />}
        />
        <Route path="/error" exact component={ErrorScreen} />

        {/* Catch-all fallthrough */}
        <Route render={() => <Redirect to="/" />} />
      </Switch>
    )}
  </Dashboard>
);

export default SubscribedRoutes;
