import * as React from "react";
import { AppContext } from "../App";
import { Redirect } from "react-router-dom";
import AppHeader from "../components/AppHeader";
import CircularProgress from "@material-ui/core/CircularProgress";
import FullScreenCard from "../design-system/FullScreenCard";
import SuccessMessage from "../design-system/SuccessMessage";
import Typography from "@material-ui/core/Typography/Typography";

const PaymentSuccess = () => {
  const ctx = React.useContext(AppContext);
  const [status, setStatus] = React.useState("LANDED");

  if (ctx.authApi == null) {
    // eslint-disable-next-line no-console
    console.warn("Landed on PaymentSuccess without cached user token");
    return <Redirect to="/error" />;
  }

  const { authApi } = ctx;

  const forgetAndRedirect = async () => {
    setStatus("GOT_SUB");
    ctx.gotUserSubscription();
    setTimeout(() => setStatus("REDIRECT"), 3000);
  };

  const queryForSubscription = async () =>
    await setTimeout(async () => {
      const [, userHasSub] = await authApi.authUserHasSubscription();

      return userHasSub ? forgetAndRedirect() : await queryForSubscription();
    }, 3000);

  React.useEffect(() => {
    queryForSubscription();
  });

  const message = (() => {
    switch (status) {
      case "LANDED":
        return "Your purchase was successful! Waiting for systems to sync up, this can take up to 60 seconds.";

      case "GOT_SUB":
      case "REDIRECT":
      default:
        return "Thank you for your purchase! You are being redirected to the Dashboard...";
    }
  })();

  return (
    <div>
      <AppHeader />
      <FullScreenCard>
        <Typography component="h1" variant="h5">
          Payment Success
        </Typography>
        <SuccessMessage>{message}</SuccessMessage>
        {status === "LANDED" ? <CircularProgress size={20} /> : null}
        {status === "REDIRECT" ? <Redirect to="/" /> : null}
      </FullScreenCard>
    </div>
  );
};

export default PaymentSuccess;
