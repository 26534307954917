import * as Api from "../Api";
import * as React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { setFromDomEvent } from "../utils/hooks";
import AppHeader from "../components/AppHeader";
import Button from "@material-ui/core/Button/Button";
import ErrorMessage from "../design-system/ErrorMessage";
import FormControl from "@material-ui/core/FormControl/FormControl";
import FullScreenCard from "../design-system/FullScreenCard";
import Input from "@material-ui/core/Input/Input";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import SuccessMessage from "../design-system/SuccessMessage";
import Typography from "@material-ui/core/Typography/Typography";

const useStyles = makeStyles(theme => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  }
}));

const ForgotPassword = () => {
  const classes = useStyles();
  const [email, setEmail] = React.useState("");
  const [emailSendStatus, setEmailSendStatus] = React.useState("NONE");

  const setFormInput = setter => e => {
    setEmailSendStatus("NONE");
    setFromDomEvent(setter, e);
  };
  const updateEmail = setFormInput(setEmail);
  const handleSubmit = async event => {
    event.preventDefault();
    setEmailSendStatus("SENDING");

    const [error] = await Api.forgotPassword(email);

    if (error) return setEmailSendStatus("FAILURE");

    setEmailSendStatus("SUCCESS");
  };

  const showForm = emailSendStatus !== "SUCCESS";
  const showSubmitButton = emailSendStatus !== "SENDING";
  const sendStatus = (() => {
    switch (emailSendStatus) {
      case "NONE":
        return null;

      case "FAILURE":
        return (
          <ErrorMessage>
            Could not send reset link for this email address. Are you sure its
            correct?
          </ErrorMessage>
        );

      case "SUCCESS":
        return (
          <SuccessMessage>{`Recovery email sent to ${email}!`}</SuccessMessage>
        );

      case "SENDING":
        return (
          <Typography component="h2" variant="subtitle1">
            Sending...
          </Typography>
        );
    }
  })();

  return (
    <div>
      <AppHeader />
      <FullScreenCard>
        <Typography component="h1" variant="h5">
          Password Recovery
        </Typography>
        {showForm ? (
          <form className={classes.form} onSubmit={handleSubmit}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="email">Email Address</InputLabel>
              <Input
                id="email"
                name="email"
                autoComplete="email"
                type="email"
                autoFocus
                value={email}
                onChange={updateEmail}
              />
            </FormControl>
            {showSubmitButton ? (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Send Email
              </Button>
            ) : null}
          </form>
        ) : null}
        {sendStatus}
        {emailSendStatus === "SUCCESS" ? (
          <Typography component={"h2"} variant={"subtitle1"}>
            <Link to="/">Go Back</Link>
          </Typography>
        ) : null}
      </FullScreenCard>
    </div>
  );
};

export default ForgotPassword;
