import * as React from "react";
import AppHeader from "../components/AppHeader";
import FullScreenCard from "../design-system/FullScreenCard";
import InnerCircleHelpDialog from "../components/IcHelpDialog";

export const Discount = () => (
  <div>
    <AppHeader />
    <FullScreenCard wide>
      <InnerCircleHelpDialog />
    </FullScreenCard>
  </div>
);
