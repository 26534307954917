import * as React from "react";
import { Discount } from "./screens/Discount";
import { Redirect, Route, Switch } from "react-router-dom";
import AccountDeletionScreen from "./screens/AccountDeletionScreen";
import ClaimAccount from "./screens/ClaimAccount";
import ErrorScreen from "./Error";
import ForgotPassword from "./screens/ForgotPassword";
import NotSignedInRoot from "./screens/NotSignedInRoot";
import PrivacyPolicyScreen from "./screens/PrivacyPolicyScreen";
import ResetPassword from "./screens/ResetPassword";
import Signup from "./screens/Signup";
import Support from "./screens/Support";

const NotSignedInRoutes = () => (
  <Switch>
    <Route path="/signin" exact component={NotSignedInRoot} />
    <Route path="/error" exact component={ErrorScreen} />
    <Route path="/forgot-password" component={ForgotPassword} />
    <Route path="/reset-password" component={ResetPassword} />
    <Route path="/claim-account" component={ClaimAccount} />
    <Route path="/signup" component={Signup} />
    <Route path="/privacy" component={PrivacyPolicyScreen} />
    <Route path="/account-deletion" component={AccountDeletionScreen} />
    <Route path="/support" component={Support} />
    <Route path="/discount" component={Discount} />
    {/* Catch-all fallthrough */}
    <Route
      render={p => (
        <Redirect
          to={{
            pathname: "/signin",
            state: {
              onAuth: p.location.pathname
            }
          }}
        />
      )}
    />
  </Switch>
);

export default NotSignedInRoutes;
