import * as React from "react";
import { AppContext } from "../App";
import { Redirect, withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button/Button";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Env from "../Env";
import ErrorMessage from "../design-system/ErrorMessage";
import FullScreenCard from "../design-system/FullScreenCard";
import StripeCheckout from "react-stripe-checkout";
import SuccessMessage from "../design-system/SuccessMessage";
import Typography from "@material-ui/core/Typography/Typography";

const UpdateCardInfoView = props => {
  const classes = makeStyles(theme => ({
    bodyContent: {
      marginBottom: theme.spacing.unit,
      textAlign: "center"
    }
  }))();

  const cardNumber = (() => {
    const noCards = (
      <Typography component="p" className={classes.bodyContent}>
        You currently have no cards on file
      </Typography>
    );

    if (
      !props.userInfo ||
      !props.userInfo.cards ||
      !props.userInfo.defaultCardId
    )
      return noCards;

    const defaultCard = props.userInfo.cards[props.userInfo.defaultCardId];

    if (!defaultCard) return noCards;

    return (
      <Typography
        component="p"
        variant="caption"
        className={classes.bodyContent}
      >
        {`${defaultCard.brand || "??"} - XXXXXXXXXX${defaultCard.last4 ||
          "0000"} - Exp ${defaultCard.exp_month ||
          "00"}/${defaultCard.exp_year || "0000"}`}
      </Typography>
    );
  })();

  return (
    <FullScreenCard>
      <Typography component="h1" variant="h5">
        Update Card Info
      </Typography>
      <div>
        <>
          {props.userSub == null ? null : (
            <Typography component="p" className={classes.bodyContent}>
              Next Renewal:{" "}
              <strong>
                {new Date(
                  props.userSub.data.current_period_end * 1000
                ).toLocaleDateString()}
              </strong>
            </Typography>
          )}
          {cardNumber}
        </>
      </div>
      {props.showButton ? (
        <StripeCheckout
          name="Waterfall Streaming"
          description="Update Your Card Info Below"
          token={props.onToken}
          allowRememberMe={false}
          stripeKey={Env.REACT_APP_STRIPE_API_KEY}
          email={props.userInfo == null ? "" : props.userInfo.email}
          panelLabel="Update Card Details"
        >
          <Button fullWidth variant="contained" color="primary">
            Update
          </Button>
        </StripeCheckout>
      ) : null}
      {props.sendStatusComponent}
    </FullScreenCard>
  );
};

const UpdateCardInfo = props => {
  const ctx = React.useContext(AppContext);
  const [backendSendStatus, setBackendSendStatus] = React.useState("NONE");
  const { authApi } = ctx;
  if (authApi == null) return <Redirect to="/error" />;

  const onToken = async card => {
    setBackendSendStatus("SENDING");

    const [error] = await authApi.updateCardInfo(card.id);

    if (error) return setBackendSendStatus("FAILURE");

    setBackendSendStatus("SUCCESS");
    setTimeout(() => {
      props.history.push("/", {
        refresh: Date.now()
      });
    }, 3000);
  };

  const sendStatus = (() => {
    switch (backendSendStatus) {
      case "NONE":
        return null;

      case "FAILURE":
        return (
          <ErrorMessage>
            Could not update your card info at this time. Please contact support
            for assistance.
          </ErrorMessage>
        );

      case "SUCCESS":
        return (
          <SuccessMessage>
            Your card info has been successfully updated!
          </SuccessMessage>
        );

      case "SENDING":
        return (
          <Typography component="h2" variant="subtitle1">
            Updating... {<CircularProgress size={20} />}
          </Typography>
        );
    }
  })();

  return (
    <UpdateCardInfoView
      {...props}
      onToken={onToken}
      showButton={backendSendStatus !== "SUCCESS"}
      sendStatusComponent={sendStatus}
    />
  );
};

export default withRouter(UpdateCardInfo);
