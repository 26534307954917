import invariant from "invariant";

const getEnv = envKey => {
  const env = process.env[envKey];
  invariant(env != null, `${envKey} must be defined in .env`);
  return env;
};

const env = {
  REACT_APP_REST_API_HOST: getEnv("REACT_APP_REST_API_HOST"),
  REACT_APP_RECAPTCHA_SITE_KEY: getEnv("REACT_APP_RECAPTCHA_SITE_KEY"),
  REACT_APP_STRIPE_API_KEY: getEnv("REACT_APP_STRIPE_API_KEY"),
  REACT_APP_STRIPE_BASE_PRODUCT_KEY: getEnv(
    "REACT_APP_STRIPE_BASE_PRODUCT_KEY"
  ),
  REACT_APP_STRIPE_DISCOUNT_PRODUCT_KEY: getEnv(
    "REACT_APP_STRIPE_DISCOUNT_PRODUCT_KEY"
  )
};

export default env;
