import * as Api from "../Api";
import * as React from "react";
import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { passwordIsValid } from "../utils/validation";
import { setFromDomEvent } from "../utils/hooks";
import AppHeader from "../components/AppHeader";
import Button from "@material-ui/core/Button/Button";
import ErrorMessage from "../design-system/ErrorMessage";
import FormControl from "@material-ui/core/FormControl/FormControl";
import FullScreenCard from "../design-system/FullScreenCard";
import Input from "@material-ui/core/Input/Input";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import SuccessMessage from "../design-system/SuccessMessage";
import Typography from "@material-ui/core/Typography/Typography";
import queryString from "query-string";

const useStyles = makeStyles(theme => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  }
}));

const ResetPassword = props => {
  const classes = useStyles();
  const [newPass, setNewPass] = React.useState("");
  const [newPassConfirm, setNewPassConfirm] = React.useState("");
  const [sendStatus, setSendStatus] = React.useState({ type: "NONE" });

  const code = queryString.parse(props.location.search).code;

  const setFormInput = setter => e => {
    setSendStatus({ type: "NONE" });
    setFromDomEvent(setter, e);
  };
  const updateNewPass = setFormInput(setNewPass);
  const updateNewPassConfirm = setFormInput(setNewPassConfirm);

  const handleSubmit = async event => {
    event.preventDefault();

    if (newPass !== newPassConfirm)
      return setSendStatus({
        type: "INVALID",
        message: "Passwords did not match."
      });

    if (!passwordIsValid(newPass))
      return setSendStatus({
        type: "INVALID",
        message: "Passwords must be at least 6 characters long."
      });

    if (code == null)
      return setSendStatus({
        type: "INVALID",
        message: "Missing required information from email."
      });

    setSendStatus({ type: "SENDING" });
    const [error] = await Api.resetPassword(code, newPass, newPassConfirm);

    if (error)
      return setSendStatus({
        type: "FAILURE",
        message: error.message
      });

    setSendStatus({ type: "SUCCESS" });
    setTimeout(() => setSendStatus({ type: "SUCCESS_REDIRECT" }), 3000);
  };

  const showForm =
    sendStatus.type !== "SUCCESS" && sendStatus.type !== "SUCCESS_REDIRECT";
  const showSubmitButton = sendStatus.type !== "SENDING" && showForm;
  const sendStatusRender = (() => {
    switch (sendStatus.type) {
      case "NONE":
        return null;

      case "INVALID":
      case "FAILURE":
        return <ErrorMessage>{sendStatus.message}</ErrorMessage>;

      case "SENDING":
        return (
          <Typography component="h2" variant="subtitle1">
            Resetting...
          </Typography>
        );

      case "SUCCESS":
        return (
          <SuccessMessage>
            Password changed! Redirecting to login...
          </SuccessMessage>
        );

      case "SUCCESS_REDIRECT":
        return <Redirect to="/" />;
    }
  })();

  return (
    <div>
      <AppHeader />
      <FullScreenCard>
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        {showForm ? (
          <form className={classes.form} onSubmit={handleSubmit}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">Password</InputLabel>
              <Input
                name="password"
                type="password"
                id="password"
                value={newPass}
                autoFocus
                onChange={updateNewPass}
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="confirmPassword">
                Confirm Password
              </InputLabel>
              <Input
                name="confirmPassword"
                type="password"
                id="confirmPassword"
                value={newPassConfirm}
                onChange={updateNewPassConfirm}
              />
            </FormControl>
            {showSubmitButton ? (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Ok
              </Button>
            ) : null}
          </form>
        ) : null}
        {sendStatusRender}
      </FullScreenCard>
    </div>
  );
};

export default ResetPassword;
