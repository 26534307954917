import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import Button from "@material-ui/core/es/Button/Button";
import Logo from "../waterfall-logo.png";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography/Typography";

const propTypes = {
  subExpireTime: PropTypes.number,
  showPersistentDrawer: PropTypes.bool.isRequired
};

const DashboardHome = props => {
  const classes = makeStyles(theme => ({
    toolbar: theme.mixins.toolbar,
    logoImage: {
      display: "block",
      margin: "auto",
      height: "6em",
      marginBottom: theme.spacing.unit * 2
    },
    bodyContent: {
      marginBottom: theme.spacing.unit,
      textAlign: "center"
    },
    buttonContainer: {
      margin: "auto",
      width: "200px"
    },
    button: {
      marginTop: theme.spacing.unit * 2
    }
  }))();

  return (
    <div>
      {props.subExpireTime == null ? null : <div className={classes.toolbar} />}
      <img
        src={Logo}
        alt="waterfall by Radiant Records"
        className={classes.logoImage}
      />
      <Typography component="p" className={classes.bodyContent}>
        {"Use the " +
          (props.showPersistentDrawer ? "links to the left" : "menu") +
          " to manage your account"}
      </Typography>
      <div className={classes.buttonContainer}>
        <a href="https://itunes.apple.com/app/id1449301230?mt=8 ">
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Download iOS
          </Button>
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.radiantstreaming.app">
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Download Android
          </Button>
        </a>
        <a href="ms-windows-store://pdp/?productid=9N0QVZF814B0">
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Download Windows
          </Button>
        </a>
      </div>
    </div>
  );
};

DashboardHome.propTypes = propTypes;

export default DashboardHome;
