import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ErrorScreen from "./Error";
import PaymentCancel from "./screens/PaymentCancel";
import PaymentSuccess from "./screens/PaymentSuccess";
import Subscribe from "./screens/Subscribe";

const NotSubscribedRoutes = props => (
  <Switch>
    <Route
      exact
      path="/"
      render={p => <Subscribe user={props.user} jwt={props.token} {...p} />}
    />
    <Route path="/error" exact component={ErrorScreen} />
    <Route path="/payment-success" component={PaymentSuccess} />
    <Route path="/payment-cancel" component={PaymentCancel} />

    {/* Catch-all fallthrough */}
    <Route render={() => <Redirect to="/" />} />
  </Switch>
);

export default NotSubscribedRoutes;
